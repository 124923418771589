.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: OptumSansRegular;
  src: url(./fonts/OptumSans-Regular.otf)
}

h1{
  font-family: OptumSansRegular,Arial,sans-serif !important;
}

hr{
  border-top: 3px solid rgba(0,0,0,.1);
}

.infoBar {
  background-color:#faf9f4!important;;
  color: #fff;
  height: 30px;
  position: relative;
  z-index: 10;
}


#login-container{
  margin: auto;
  margin-top: 50px;
  height: 225px;
  width: 800px;
  
}
.text {
  margin-bottom: 50px;
  color: #4b4d4f;
  text-align: center;
  font-size: 18px;
  font-family: OptumSansRegular,Arial,sans-serif !important;
}
#wrapper{
  width: 100%;
  margin: auto;
  margin-top: 3%;
}
.content {
  margin-left: 10%;
  margin-right: 10%;
}
#btnheader{
  margin-left: 5%;
  margin-top: 7%;
  font-family: OptumSansRegular,Arial,sans-serif !important;
}

#signonbtn {
  margin-left: 5%;
  margin-top: 3%;
  width: 100px;
  cursor: pointer;
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
  padding: 6px;
  border: 1px solid transparent;
  border-radius: 4px!important;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  background-color: #316bbe;
  color: #fff;
  font-size: 16px;
  font-family: OptumSansRegular,Arial,sans-serif !important;
  text-decoration: none;
  text-align: center;
}

#altsignin {
  margin-left: 3%;
  margin-top: 3%;
  font-family: OptumSansRegular,Arial,sans-serif !important;
}

#altsignin a {
  text-decoration: underline;
  cursor: pointer;
  font-family: OptumSansRegular,Arial,sans-serif !important;
}

#login-content {
  display: inline-block;
  margin-left: 240px;
  width: 400px;
}
#mainimg {
  display: inline-flex;
  width: 246px;
  vertical-align: top;
  margin-top: 30px;
  margin-left: 30px;
}

.bui-button {
  border: 0.0625rem solid #002677;
  background-color: #002677;
  color: white;
  border-radius: 62.4375rem;
  font-family: "Optum Sans",Helvetica,Arial,sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25rem;
  min-height: 2.5rem;
  min-width: 6.25rem;
  padding: 0.25rem 1.5rem;

  margin-left: 5%;
  margin-top: 3%;
  width: 300px;
  cursor: pointer;
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
  padding: 6px;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  text-align: center;
  
}

.bui-button-footer {
  border: 2px solid white !important;
  border-radius: 62.4375rem;
  color: white;
  padding: 12px !important;
  width: 250px !important;
  height: 50px !important;
  vertical-align: top !important;
}

.envLabel {
  font-weight: bold;
  font-size: larger;
  margin-left: 30px;
}

.date-time{
  padding-left:4px;
  color:white;
  font-size: 12.8px;
  font-family: "Frutiger", Arial, sans-serif;
  width: 5px;
  margin-top: 20px;
}

#pageHeader {
  font-family: "Optum Sans",Helvetica,Arial,sans-serif;
  color: #002677;
  font-size: 2.25rem;
  font-weight: 900 !important;
  font-size: 2.5rem;
  line-height: 1.2;
  letter-spacing: -1px;
  text-align: center;
}

.footerContainer{
  position: absolute;
  bottom: 0;
  width: 100% !important;
  height: 200px !important; 
  border-top: 0.25rem solid #ff612b!important;
  height: 5rem!important;
  flex: 0 0 50px!important;
  padding-left: 10px;
  min-height: 50px!important;
  }


  /* environment buttons */


  .footer-btn-local {
    background-color: #316BBE;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    margin-top: 20px;
    margin-right: 20px;
}

.footer-btn-dev {
    background-color: #a62626;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    margin-top: 20px;
    margin-right: 20px;
}

.footer-btn-qa {
    background-color: #43277c;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    margin-top: 20px;
    margin-right: 20px;
}

.footer-btn-stg {
    background-color: #008272;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    margin-top: 20px;
    margin-right: 20px;
}

.footer-btn-prod {
    background-color: #e5710b;
    position: absolute !important;
    top: 0px !important;
    right: 0px !important;
    margin-top: 13px;
    margin-right: 20px;
}

.bui-footer-copy {
  line-height: .7 !important;
}



