#formWrapper{
    width: 100%;
    margin: auto;
}
#submitBtn {
    margin-left: 10px;
    width: 100px;
    cursor: pointer;
    display: inline-block;
    line-height: normal;
    vertical-align: middle;
    padding: 6px;
    border: 1px solid transparent;
    border-radius: 4px!important;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    background-color: #316bbe;
    color: #fff;
    font-size: 16px;
    font-family: OptumSansRegular,Arial,sans-serif !important;
    text-decoration: none;
    text-align: center;  
}

#userWarning {
    text-align: center;
}

#selectList {
    height: 35px;
    vertical-align: bottom;
}

#formBox {
margin-top: 100px;
width: 300px;
margin: auto;
}

#infoBar{
  background-color: #000;
  color: #fff;
  height: 30px;
  position: relative;
  z-index: 10;
  margin-bottom: 100px;

}